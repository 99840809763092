type CookieName =
    | "i18n_redirected"
    | "kadewe_loyalty_staging_session"
    | "xsrf-token"
    | "google_analytics"
    | "meta_pixel";

export const useCookieStore = defineStore("cookies", () => {
    const { gtag, initialize } = useGtag();
    const nuxtApp = useNuxtApp();

    const necessaryCookies = ["i18n_redirected", "kadewe_loyalty_staging_session", "xsrf-token"];
    const isSet = ref(false);

    const cookieStatus = ref({
        i18n_redirected: true,
        kadewe_loyalty_staging_session: true,
        "xsrf-token": true,
        google_analytics: null,
        meta_pixel: null,
    });

    const isEverythingEnabled = computed(() => {
        return Object.values(cookieStatus.value).every((value) => {
            return value === true;
        });
    });

    const isGoogleAnalyticsEnabled = computed(() => {
        return cookieStatus.value.google_analytics === true;
    });

    const isMetaPixelEnabled = computed(() => {
        return cookieStatus.value.meta_pixel === true;
    });

    const loadCookies = () => {
        // Load the cookie state from localStorage and return if they were already set
        const cookieState = localStorage.getItem("cookies");
        if (cookieState !== null) {
            Object.assign(cookieStatus.value, JSON.parse(cookieState));
            return true;
        }

        return false;
    };

    const updateCookie = (cookieName: CookieName, value: boolean): boolean => {
        if (necessaryCookies.includes(cookieName)) {
            console.warn(`Cannot change the status of necessary cookie ${cookieName}`);
            return false;
        }

        // Update the cookie state but don't save it to localStorage yet
        cookieStatus.value[cookieName] = value;
        return true;
    };

    const saveCookies = () => {
        localStorage.setItem("cookies", JSON.stringify(cookieStatus.value));
        isSet.value = true;

        initScripts();
    };

    const denyOptionalCookies = () => {
        for (const cookieName in cookieStatus.value) {
            if (necessaryCookies.includes(cookieName as CookieName)) {
                continue;
            }

            updateCookie(cookieName as CookieName, false);
        }

        saveCookies();
    };

    const acceptAllCookies = () => {
        for (const cookieName in cookieStatus.value) {
            updateCookie(cookieName as CookieName, true);
        }

        saveCookies();
    };

    const initScripts = () => {
        // Handle Google Analytics
        if (isGoogleAnalyticsEnabled) {
            console.debug("Initializing google analytics");

            initialize();

            gtag("consent", "update", {
                analytics_storage: "granted",
            });
        } else {
            gtag("consent", "update", {
                ad_storage: "denied",
                analytics_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
            });
        }

        // Handle meta pixel
        if (isMetaPixelEnabled) {
            console.debug("Initializing meta pixel");

            // Initialize meta pixel
            // @ts-expect-error - Meta pixel is not yet typed
            nuxtApp.$fb.enable();
        } else {
            // @ts-expect-error - Meta pixel is not yet typed
            nuxtApp.$fb.disable();
        }
    };

    const trackEvent = (name: string, payload: object | undefined = undefined) => {
        try {
            if (isGoogleAnalyticsEnabled) {
                gtag("event", name, payload);
            }

            if (isMetaPixelEnabled) {
                // @ts-expect-error - Meta pixel is not yet typed
                nuxtApp.$fb.track(name, payload);
            }
        } catch (error) {
            console.error("Failed to track event", error);
        }
    };

    isSet.value = loadCookies();
    initScripts();

    return {
        cookieStatus,
        isSet,
        isEverythingEnabled,
        isGoogleAnalyticsEnabled,
        isMetaPixelEnabled,
        updateCookie,
        saveCookies,
        denyOptionalCookies,
        acceptAllCookies,
        trackEvent,
    };
});
